const LOCAL_DEV_PORT = 443;

module.exports = {
  development: {
    host: "db",
    s3Bucket: "noipm-local",
    officerBucket: "nopd-officers-local",
    exportsBucket: "noipm-exports-local",
    referralLettersBucket: "noipm-referral-letters-local",
    complainantLettersBucket: "noipm-complainant-letters-local",
    authentication: {
      engine: "okta",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://localhost/callback`,
      domain: "dev-68895481.okta.com",
      publicKeyURL: "https://dev-68895481.okta.com/oauth2/default/v1/keys",
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      algorithm: "RS256",
      nicknameKey: "sub",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://dev-68895481.okta.com/",
        "https://noipm-ci.auth0.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://api.github.com",
        `wss://localhost:${LOCAL_DEV_PORT}`,
      ],
    },
    corsOrigin: "*",
    winston: {
      logLevel: "info",
      json: true,
    },
    queue: {
      host: "redis",
      port: 6379,
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  test: {
    host: process.env.CIRCLECI ? "localhost" : "db",
    s3Bucket: "noipm-local",
    port: 5432,
    exportsBucket: "noipm-exports-test",
    referralLettersBucket: "noipm-referral-letters-test",
    complainantLettersBucket: "noipm-complainant-letters-test",
    authentication: {
      engine: "okta",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://localhost/callback`,
      domain: "dev-68895481.okta.com",
      publicKeyURL: "https://dev-68895481.okta.com/oauth2/default/v1/keys",
      audience: "test audience",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      algorithm: "RS256",
      nicknameKey: "sub",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://dev-68895481.okta.com/",
        "https://noipm-ci.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        `wss://localhost:${LOCAL_DEV_PORT}`,
      ],
    },
    corsOrigin: "*",
    winston: {
      logLevel: "error",
      json: true,
    },
    queue: {
      host: "redis",
      port: 6379,
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  playground: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "noipm-playground",
    officerBucket: "nopd-officers-playground",
    exportsBucket: "noipm-exports-playground",
    referralLettersBucket: "noipm-referral-letters-playground",
    complainantLettersBucket: "noipm-complainant-letters-playground",
    authentication: {
      engine: "okta",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://noipm-playground.herokuapp.com/callback`,
      domain: "dev-68895481.okta.com",
      publicKeyURL: "https://dev-68895481.okta.com/oauth2/default/v1/keys",
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      algorithm: "RS256",
      nicknameKey: "sub",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://dev-68895481.okta.com/",
        "https://noipm-ci.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        "https://axi8fqxdfk.execute-api.us-east-1.amazonaws.com",
      ],
    },
    corsOrigin: "https://noipm-playground.herokuapp.com",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  ci: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "hcsoc-ci",
    officerBucket: "nopd-officers-ci",
    exportsBucket: "noipm-exports-ci",
    referralLettersBucket: "noipm-referral-letters-ci",
    complainantLettersBucket: "noipm-complainant-letters-ci",
    elasticIndexId:
      "noipm-deployment:dXMtZWFzdC0xLmF3cy5mb3VuZC5pbzo0NDMkYTM5MTE0NGNkNWEyNGY5N2I4Y2IwNjYzNGFjZTMzZDMk",
    authentication: {
      engine: "okta",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://hcsoc-ci-b16ff838b089.herokuapp.com/callback`,
      domain: "dev-68895481.okta.com",
      publicKeyURL: "https://dev-68895481.okta.com/oauth2/default/v1/keys",
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      algorithm: "RS256",
      nicknameKey: "sub",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://dev-68895481.okta.com/",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        "https://dp3gvzd2gk.execute-api.us-east-1.amazonaws.com",
      ],
    },
    corsOrigin: "https://noipm-ci.herokuapp.com",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  staging: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "hcsoc-staging",
    officerBucket: "nopd-officers-staging",
    exportsBucket: "noipm-exports-staging",
    referralLettersBucket: "noipm-referral-letters-staging",
    complainantLettersBucket: "noipm-complainant-letters-staging",
    elasticIndexId:
      "noipm-deployment:dXMtZWFzdC0xLmF3cy5mb3VuZC5pbzo0NDMkYTM5MTE0NGNkNWEyNGY5N2I4Y2IwNjYzNGFjZTMzZDMk",
    authentication: {
      engine: "okta",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://hcsoc-staging-0171a859e889.herokuapp.com/callback`,
      domain: "dev-68895481.okta.com",
      publicKeyURL: "https://dev-68895481.okta.com/oauth2/default/v1/keys",
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      algorithm: "RS256",
      nicknameKey: "sub",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://dev-68895481.okta.com/",
        "https://noipm-staging.auth0.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        "https://jxjagpncze.execute-api.us-east-1.amazonaws.com",
      ],
    },
    corsOrigin: "https://hcsoc-staging-0171a859e889.herokuapp.com",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-east-1",
      tls: true,
    },
  },
  production: {
    port: 5432,
    host: process.env.DATABASE_HOST,
    s3Bucket: "hcsoc-production",
    officerBucket: "hawaii-roster-production",
    exportsBucket: "hcsoc-exports-production",
    referralLettersBucket: "hcsoc-referral-letters-production",
    complainantLettersBucket: "hcsoc-complainant-letters-production",
    elasticIndexId:
      "hcsoc-deployment:dXMtd2VzdC0yLmF3cy5mb3VuZC5pbzo0NDMkYWFkNTlkNDU2YTk2NGUxZjg5MzRjMjM5ZDg5ZDA5YTgkZDA5NGMyNDZjYjdjNGFkZGE2NTc3YTQyZjAyNDEyMzE=",
    authentication: {
      engine: "okta",
      clientID: "0oaa9s8qdogmLTXnI5d7",
      domain: "dev-15027147.okta.com",
      redirectUri: `https://hcsoc-data.hawaii.gov/callback`,
      publicKeyURL: "https://dev-15027147.okta.com/oauth2/default/v1/keys",
      audience: "api://default",
      issuer: "https://dev-15027147.okta.com/oauth2/default",
      algorithm: "RS256",
      nicknameKey: "sub",
    },
    contentSecurityPolicy: {
      connectSrc: [
        "'self'",
        "https://dev-15027147.okta.com",
        "https://a.tile.openstreetmap.org",
        "https://b.tile.openstreetmap.org",
        "https://api.mapbox.com",
        "https://www.google-analytics.com",
        "https://www.googletagmanager.com",
        "https://maps.googleapis.com",
        "https://api.github.com",
        "https://bw68y7ern6.execute-api.us-west-2.amazonaws.com",
      ],
    },
    corsOrigin: "https://hcsoc-data.hawaii.gov",
    winston: {
      logLevel: "info",
      json: false,
    },
    queue: {
      failedJobAttempts: 1,
      exponentialDelay: 60 * 1000,
      jobTimeToLive: 120 * 1000,
      jobUIPort: 5000,
    },
    s3config: {
      region: "us-west-2",
      tls: true,
    },
  },
};
