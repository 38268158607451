const PORT = 1234;
const LOCAL_DEV_PORT = 443;

module.exports = {
  development: {
    auth: {
      engine: "okta",
      domain: "dev-68895481.okta.com",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://localhost/callback`,
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: `https://localhost:${LOCAL_DEV_PORT}`,
    backendUrl: `https://localhost:${PORT}`,
    frontendUrl: `https://localhost:${LOCAL_DEV_PORT}`,
  },
  development_e2e: {
    auth: {
      engine: "okta",
      domain: "dev-68895481.okta.com",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://localhost/callback`,
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: `https://app-e2e:${LOCAL_DEV_PORT}`,
    backendUrl: `https://app-e2e:${LOCAL_DEV_PORT}`,
    frontendUrl: `https://localhost:${LOCAL_DEV_PORT}`,
  },
  static_development: {
    auth: {
      engine: "okta",
      domain: "dev-68895481.okta.com",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://localhost/callback`,
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: `http://localhost:${PORT}`,
    backendUrl: `https://localhost:${PORT}`,
    frontendUrl: `https://localhost:${LOCAL_DEV_PORT}`,
  },
  test: {
    auth: {
      engine: "okta",
      domain: "dev-68895481.okta.com",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://localhost/callback`,
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: "http://localhost",
    backendUrl: `https://localhost:${PORT}`,
    frontendUrl: `https://localhost:${LOCAL_DEV_PORT}`,
  },
  playground: {
    auth: {
      engine: "okta",
      domain: "dev-68895481.okta.com",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://noipm-playground.herokuapp.com/callback`,
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: "https://axi8fqxdfk.execute-api.us-east-1.amazonaws.com/",
    backendUrl: "https://noipm-playground.herokuapp.com",
    frontendUrl: "https://noipm-playground.herokuapp.com",
  },
  ci: {
    auth: {
      engine: "okta",
      domain: "dev-68895481.okta.com",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://hcsoc-ci-b16ff838b089.herokuapp.com/callback`,
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: "https://dp3gvzd2gk.execute-api.us-east-1.amazonaws.com",
    backendUrl: "https://hcsoc-ci-b16ff838b089.herokuapp.com",
    frontendUrl: "https://hcsoc-ci-b16ff838b089.herokuapp.com",
  },
  staging: {
    auth: {
      engine: "okta",
      domain: "dev-68895481.okta.com",
      clientID: "0oa9iyby3br2VDVK05d7",
      redirectUri: `https://hcsoc-staging-0171a859e889.herokuapp.com/callback`,
      audience: "api://default",
      issuer: "https://dev-68895481.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: "https://jxjagpncze.execute-api.us-east-1.amazonaws.com",
    backendUrl: "https://hcsoc-staging-0171a859e889.herokuapp.com",
    frontendUrl: "https://hcsoc-staging-0171a859e889.herokuapp.com",
  },
  production: {
    auth: {
      engine: "okta",
      domain: "dev-15027147.okta.com",
      clientID: "0oaa9s8qdogmLTXnI5d7",
      redirectUri: "https://hcsoc-data.hawaii.gov/callback",
      audience: "api://default",
      issuer: "https://dev-15027147.okta.com/oauth2/default",
      nicknameKey: "sub",
      responseType: "token id_token",
      scope: "openid profile",
    },
    hostname: "https://bw68y7ern6.execute-api.us-west-2.amazonaws.com",
    backendUrl: "https://hcsoc-data.hawaii.gov",
    frontendUrl: "https://hcsoc-data.hawaii.gov",
  },
};
